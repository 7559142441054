/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//window.Vue = require('vue');
import Vue from 'vue'
import Vuesax from 'vuesax';
//import 'material-icons/iconfont/material-icons.css';
//import 'vuesax/dist/vuesax.css'


Vue.use(Vuesax, {
    // options here
})

//Grid
Vue.component('row', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/row.vue').default);
Vue.component('col-1', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-1.vue').default);
Vue.component('col-2', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-2.vue').default);
Vue.component('col-3', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-3.vue').default);
Vue.component('col-4', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-4.vue').default);
Vue.component('col-5', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-5.vue').default);
Vue.component('col-6', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-6.vue').default);
Vue.component('col-7', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-7.vue').default);
Vue.component('col-8', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-8.vue').default);
Vue.component('col-9', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-9.vue').default);
Vue.component('col-10', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-10.vue').default);
Vue.component('col-11', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-11.vue').default);
Vue.component('col-12', require('./../../vendor/ithilbert/laravel-kit/vue/bootstrap4/grid/col-12.vue').default);


//Cookie Disclaimer
Vue.component('cookie-disclaimer', require('./../../vendor/ithilbert/cookie-disclaimer/src/Resources/Vue/cookies-disclaimer.vue').default);
Vue.component('cookies-allow-reset', require('./../../vendor/ithilbert/cookie-disclaimer/src/Resources/Vue/cookies-allow-reset.vue').default);
Vue.component('cookies-infos', require('./../../vendor/ithilbert/cookie-disclaimer/src/Resources/Vue/cookies-infos.vue').default);

//Kontaktformular
Vue.component('kontaktformular', require('./../../vendor/ithilbert/kontaktformular/src/Resources/Vue/kontaktformular.vue').default);

//Slider
Vue.component('vue-carousel', require('@chenfengyuan/vue-carousel/src/carousel.vue').default);
Vue.component('bewertung1', require('./components/bewertung1.vue').default);
Vue.component('bewertung2', require('./components/bewertung2.vue').default);
Vue.component('bewertung3', require('./components/bewertung3.vue').default);
Vue.component('bewertung4', require('./components/bewertung4.vue').default);
Vue.component('bewertung5', require('./components/bewertung5.vue').default);
Vue.component('bewertung6', require('./components/bewertung6.vue').default);

//Mail Link an kontakt
Vue.component('link-mail', require('./components/link-mail.vue').default);

const app = new Vue({
    el: '#vue-app',
});
